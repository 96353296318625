import React, {Component} from "react";
import {Routes, Route} from "react-router-dom";

import Orders from "./Orders.jsx";
import Users from "./Users";
import Profile from "./Profile";
import Security from './Security';
import Reports from '../Reports';
import NotFound from "./NotFound.jsx";
import LogOut from "./Logout"
import FAQ from "../FAQ";

export default class Main extends Component {
    render() {
        return (
            <Routes>
                <Route exact path="/" element={<Orders/>}/>
                <Route exact path="/users" element={<Users/>}/>
                <Route exact path="/profile" element={<Profile/>}/>
                <Route path="/security" element={<Security/>}/>
                <Route path="/reports"  element={<Reports/>}/>
                <Route path="/faq" element={<FAQ/>}/>
                <Route exact path="/logout" element={<LogOut/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        );
    }
}
