import React, {  useState, useRef } from 'react';
import t from "../../translation";
import { ModalWindow, ModalOverlay } from "../OrderDetails/OrderModal";
import { PrimaryButton, SecondaryButton } from "../styled/Buttons";
import { Summary } from "../OrderDetails/OrderTop";
import { Table, TBody, Thead, Tr, Th, Td } from "../OrderDetails/OrderTable";
import ShippedArticles from '../ChangeModalComponents/ShippedArticles';
import RemainingArticles from '../ChangeModalComponents/RemainingArticles';
import Sum from '../ChangeModalComponents/Sum';

import "./style.scss";


const InvoiceChangeModal = (props) => {
    const [remainingItemsPrice, setRemainingItemsPrice] = useState({vat:0, price_ex_vat:0});
    const [removedItems, setRemovedItems] = useState([]);
    const [changeIsValid, setChangeIsValid] = useState(false);
    const childRef = useRef();
    const [comment, setComment] = useState("");


    const onRemainingItemsChanged = (value) => {
        setRemainingItemsPrice({vat:value.vat, price_ex_vat:value.price_ex_vat});
        setRemovedItems(value.removed_items);
        setChangeIsValid(removedItems.length > 0);
    }

    const totalVatAmount = ()  => {
        let shippedItemsVat = props.order.shipped_cart_items.reduce((p, c) => p + c.vat_amount.amount * c.quantity, 0);
        return shippedItemsVat + remainingItemsPrice.vat;
    }

    const changeOrder = () => {
        props.onChange(removedItems, comment).then(()=>{
            //do nothing
        }, (rej) => {
            //
        }).catch((err) => {
            console.log(err + ", försök igen senare."); //really bad
        })
    }

    const totalPriceExclVat = ()  => {
        let shippedItemsExclVat = props.order.shipped_cart_items.reduce((p, c) => p + c.price_ex_vat.amount * c.quantity, 0);
        return shippedItemsExclVat + remainingItemsPrice.price_ex_vat;
    }

    return (
        <ModalOverlay onClick={ (ev) => { ev.stopPropagation(); props.onClose(); } }>
            <ModalWindow id="invoice-change-modal" onClick={(ev) => { ev.stopPropagation(); }}>                   
                <Summary>{t(`ORDER_DETAILS_CHANGE_ITEMS_HEADER`)}</Summary>
                <Table id={"order-details-summary-table"}>
                    <Thead>
                        <tr>
                            <Th>{ t(`ORDER_DETAILS_PRODUCT_NAME`) }</Th>
                            <Th compress>{ t(`ORDER_DETAILS_PRODUCT_ID`) }</Th>
                            <Th compress>{t(`ORDER_DETAILS_QUANTITIY`)}</Th>
                            <Th compress noWrap rightAlign>{ t(`ORDER_DETAILS_PRICE_PER_ITEM`) }</Th>
                            <Th compress noWrap rightAlign>{ t(`ORDER_DETAILS_PRICE`) }</Th>
                            <Th compress noWrap rightAlign></Th>
                        </tr>
                    </Thead>
                    <TBody>
                        <RemainingArticles 
                            ref={childRef}
                            remaining_cart_items={props.order.remaining_cart_items} 
                            onRemainingItemsChangedCallback = {(value) => onRemainingItemsChanged(value)} /> 
                        <ShippedArticles shippedArticles = {props.order.shipped_cart_items}/>
                        <Tr>
                            <Td className="noPadding" colSpan='6'>
                                <div className="padding">
                                    <SecondaryButton onClick={ () => childRef.current.cancelAllItems() } fontSize="14px" fixedHeight="35px" oneLine id="cancel-all-items-btn">
                                        { t("ORDER_DETAILS_CONFIRM_CANCEL_REMAINING") }
                                    </SecondaryButton>
                                </div>
                            </Td>
                        </Tr>
                    </TBody>
                    <Sum 
                        currency = {props.order.total_price_incl_vat.currency}
                        totalVatAmount = {totalVatAmount()}
                        totalPriceExclVat = {totalPriceExclVat()}/>
                </Table>
                    
                    <div style={{ margin: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <input type="text" style={{ padding: "1em", margin: "1em 0 1em 0" }} defaultValue={comment} onChange={ e => setComment(e.target.value)} placeholder={ t(`ORDER_DETAILS_COMMENTS_FIELD`) } />
                        </div>

                        <PrimaryButton 
                            id={"order-details-change-modal-button"} 
                            onClick={ () => changeOrder() }
                            disabled={ !changeIsValid } 
                            > 
                            { t(`ORDER_DETAILS_CONFIRM_CHANGE`) }
                        </PrimaryButton>&nbsp;

                        <SecondaryButton 
                            secondary
                            id={"order-details-abort-change-modal-button"} 
                            onClick={ props.onClose.bind(this)}> 
                            { t(`MODAL_BUTTON_ABORT`) }
                        </SecondaryButton>
                    </div>
            </ModalWindow>
        </ModalOverlay>
    )


}
export default InvoiceChangeModal;
