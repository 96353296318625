import React, {useState} from 'react';
import t from "../../../translation";
import { Summary } from "../OrderTop";
import { Table, TBody, TFoot, Thead, Tr, Th,Td, TdComments} from "../OrderTable";
import { format } from "../../../utils";
import "./shipments.scss";
import AccordionWrapper from '../../AccordionWrapper';
const Shipments = (props) => {

    let formatCurrency = props.formatCurrency || format.currency;

    return (
        <div>
            <Summary>{t(`ORDER_DETAILS_ORDER_SHIPMENTS_HEADER`)}</Summary>
            {props.shipments.map((shipment, index) =>
            (<div id={`shipment_${index}`} key={shipment.id} >
                <AccordionWrapper title={format.dateWithTime(shipment.shipped_at)}>
                <Table className="leasing-order-shipping-table cart-items-table">
                    <Thead> 
                        <tr>
                            <Th>Artikel</Th>
                            <Th compress>{t(`ORDER_DETAILS_PRODUCT_ID`)}</Th>
                            <Th compress>{t(`ORDER_DETAILS_QUANTITIY`)}</Th>
                            <Th compress noWrap rightAlign>{t(`ORDER_DETAILS_PRICE`)}</Th>
                        </tr>
                    </Thead>
                    <TBody >
                        {shipment.cart_items.map(
                            (item, ii) => {
                                return (
                                    <Tr key={item.id + "_" + ii}>
                                        <Td>
                                            {item.product_name}
                                        </Td>
                                        <Td compress noWrap className="artnr-value">
                                            {item.product_id}
                                        </Td>
                                        <Td compress noWrap centerAlign>
                                            {item.quantity}
                                        </Td>
                                        <Td compress noWrap rightAlign>
                                            {formatCurrency(item.price_ex_vat.amount, item.price_ex_vat.currency)}
                                        </Td>
                                    </Tr>
                                );
                            })}
                    </TBody>
                    <TFoot>
                        {!shipment.comments
                            ? null
                            : (<tr><TdComments>{shipment.comments}</TdComments></tr>)}
                    </TFoot>
                </Table>
                </AccordionWrapper>

            </div>))}
        </div>
    )
}
export default Shipments;




