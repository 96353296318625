import React from 'react';
import t from "../../translation";
import {Summary} from "./OrderTop";
import {Table, TBody, Td, TdComments, TFoot, Th,Thead, Tr} from "./OrderTable";
import {format} from "../../utils";
import AccordionWrapper from '../AccordionWrapper';

const Cancellations = (props) => {
    let formatCurrency = props.formatCurrency || format.currency;

    return (
        <div>
            <Summary>{t(`ORDER_DETAILS_ORDER_CANCELLATIONS_HEADER`)}</Summary>
            {props.cancellations.map((cancellation, index) =>
                (<div key={cancellation.id}>
                    <AccordionWrapper title={format.dateWithTime(cancellation.canceled_at)}>
                    <Table className='cart-items-table'>
                        <Thead>
                            <tr>
                                <Th>Artiklar</Th>
                                <Th compress>{t(`ORDER_DETAILS_PRODUCT_ID`)}</Th>
                                <Th compress>{t(`ORDER_DETAILS_QUANTITIY`)}</Th>
                                <Th compress noWrap rightAlign>{t(`ORDER_DETAILS_PRICE`)}</Th>
                            </tr>
                        </Thead>
                        <TBody>
                            {cancellation.cart_items.map(
                                (item, ii) => {
                                    return (
                                        <Tr key={item.id + "_" + ii}>
                                            <Td>
                                                {item.product_name}
                                            </Td>
                                            <Td compress noWrap className="artnr-value">
                                                {item.product_id}
                                            </Td>
                                            <Td compress noWrap centerAlign>
                                                {item.quantity}
                                            </Td>
                                            <Td compress noWrap rightAlign>
                                                {formatCurrency(item.price_ex_vat.amount, item.price_ex_vat.currency)}
                                            </Td>
                                        </Tr>
                                    );
                                })}
                        </TBody>
                        <TFoot>
                            {!cancellation.comments
                                ? null
                                : (<tr><TdComments>{cancellation.comments}</TdComments></tr>)}
                        </TFoot>
                    </Table>
                    </AccordionWrapper>
    
                </div>))}
        </div>
    )
};
export default Cancellations;




