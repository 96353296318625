import React, {Component} from "react";
import ContentWrapper from "../styled/ContentWrapper";
import Hr from "../styled/Hr";
import OrdersTable from "../OrdersTable";
import OrdersFilter from "../OrdersFilter";
import OrderDetails from "../OrderDetails";
import Modal from "../Modal";
import {getInvoiceOrder, getOrder, getOrders, getOrdersAuditLog, shipCartItems, updateCartItems} from "../../services/api";
import t from "../../translation";
import orderStatus from "../../enums/orderStatus";
import {AuthService} from '../../services/authService';
import InvoiceOrderDetails from "../InvoiceOrderDetails";
import PageButtons from "../PageButtons";

export default class Orders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            counts: {},
            orders: [],
            max_count: null,
            currentOrder: null,
            currentOrderType: 'leasing',
            visibleStatuses: [orderStatus.readyToShip],
            modalVisible: false,
            modalHeader: "",
            modalTexts: [],
            modalConfirm: null,
            auditLog: null,
            activeSort: ["create_date_utc", "desc"],
            activePage: 1,
            ordersLoading: false
        };

        this.authService = new AuthService();
        this.authService.profile().catch(err => {
            console.log(err)
        });

    }

    fetchAuditLog = (id) => {
        getOrdersAuditLog(id)
            .then(res => {
                this.setState({auditLog: res.log_entries});
            })
            .catch(err => {
                this.setState({errorMessage: err.developerMessage});
            });
    };

    fetchOrders(page) {
        let pageNr = null;
        if (page) pageNr = page - 1;
        this.setState({ordersLoading: true});
        getOrders(this.state.visibleStatuses, this.state.text, pageNr ??= 0, this.state.activeSort).then((res) => {
            this.setState(state => ({
                orders: res.orders,
                counts: res.counts,
                max_count: res.max_count,
                activePage: page ??= 1
            }));
        })
        .finally(() => this.setState({ordersLoading: false}))
        .catch((err) => {
            console.log(err);
        });
    }

    componentDidMount() {
        this.fetchOrders(this.state.activePage);
    }

    openOrder(id, type) {
        this.setState({currentOrderType: type}, () => {
            this.setState({currentOrder: "loading"});
            if (type === "invoice") {
                getInvoiceOrder(id).then((res) => {
                    this.setState({currentOrder: res}, () => {
                        this.fetchAuditLog(this.state.currentOrder.id);

                    });
                }).catch((err) => {
                    console.log(err);
                });
            } else {
                getOrder(id).then((res) => {
                    this.setState({currentOrder: res}, () => {
                        this.fetchAuditLog(this.state.currentOrder.id);
                    });
                }).catch((err) => {
                    console.log(err);
                });
            }

        })

    }

    refreshOrder(id) {
        let getOrderFunction = this.state.currentOrderType !== "invoice" ? getOrder : getInvoiceOrder;
        getOrderFunction(id)
            .then((res) => {
                this.setState({currentOrder: res}, () => {
                    this.fetchAuditLog(this.state.currentOrder.id);
                });
                this.fetchOrders();
            });
    }

    onCloseDetails() {
        this.setState({currentOrder: null});
    }

    onConfirmDetails(id, items, comments) {
        this.openModal(
            t(`MODAL_HEADER_CONFIRM_ORDER`),
            [t(`MODAL_TEXT_CONFIRM_ORDER`)],
            () => {
                shipCartItems(id, items, comments)
                    .then((res) => {
                        this.closeModal();

                        return new Promise((res, rej) => {
                            setTimeout(() => {
                                res(this.refreshOrder(id));
                            }, 1000);
                        })
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
    }

    onChangeOrder(id, addedItems, canceledItems, updatedAtUtc, comments, editedShippingAmount, originalShippingAmount) {
        const updateCartItemTask = updateCartItems(id, addedItems, canceledItems, updatedAtUtc, comments, editedShippingAmount, originalShippingAmount);
        return updateCartItemTask.then((result) => {
            return new Promise((res, rej) => {
                setTimeout(() => {
                    res(this.refreshOrder(id));
                }, 1000);
            });
        });
    }

    openModal(header, texts, confirmCallback) {
        this.setState({
            modalVisible: true,
            modalHeader: header,
            modalTexts: texts,
            modalConfirm: confirmCallback
        });
    }

    closeModal() {
        this.setState({
            modalVisible: false,
            modalHeader: "",
            modalTexts: [],
            modalConfirm: null,
        });
    }

    render() {
        const toggleStatus = (status) => {
            const newVisibleStatuses = this.state.visibleStatuses;
            if (newVisibleStatuses.indexOf(status) > -1) {
                newVisibleStatuses.splice(newVisibleStatuses.indexOf(status), 1);
            } else {
                newVisibleStatuses.push(status);
            }
            this.setState({visibleStatuses: newVisibleStatuses})
            this.fetchOrders();
        }

        let textQueryDelay = null;
        const changeText = (text) => {
            clearTimeout(textQueryDelay);
            textQueryDelay = setTimeout(
                () => {
                    this.setState({text: text},()=>this.fetchOrders());    
                    //this.fetchOrders();
                },
                1000);
        };

        const applySort = (sorting) => {
            this.setState(state => ({
                activeSort: sorting
            }), () => {this.fetchOrders()});
        };

        return (
            <ContentWrapper onClick={this.onCloseDetails.bind(this)}>
                <div>
                    <OrdersFilter
                        visibleStatuses={this.state.visibleStatuses}
                        toggleStatus={toggleStatus}
                        changeText={changeText}
                        counts={this.state.counts}/>
                    {
                        this.state.currentOrderType !== "invoice" ?
                            <OrderDetails
                                order={this.state.currentOrder}
                                auditLog={this.state.auditLog}
                                key={this.state.currentOrder}
                                onClose={this.onCloseDetails.bind(this)}
                                onConfirm={this.onConfirmDetails.bind(this)}
                                onChangeOrder={(id, addedItems, cancelledItems, updatedUtc, comments, editedShippingAmount, originalShippingAmount) => this.onChangeOrder(id, addedItems, cancelledItems, updatedUtc, comments, editedShippingAmount, originalShippingAmount)}/>

                            :
                            <InvoiceOrderDetails
                                onClose={this.onCloseDetails.bind(this)}
                                order={this.state.currentOrder}
                                auditLog={this.state.auditLog}
                                refreshOrder={this.refreshOrder.bind(this)}
                            />
                    }
                    <Hr/>
                    <OrdersTable
                        orderClicked={this.openOrder.bind(this)}
                        visibleStatuses={this.state.visibleStatuses}
                        orders={this.state.orders}
                        sort={applySort}
                        activeSort={this.state.activeSort}/>
                   
                </div>

                <div className='page-buttons-wrapper'>
                    <div className='page-buttons'>
                        <PageButtons totalCount={this.state.max_count} loadPage={this.fetchOrders.bind(this)} activePage={this.state.activePage} isLoading={this.state.ordersLoading} />
                    </div>
                </div>

                <Modal
                    visible={this.state.modalVisible}
                    onAbort={this.closeModal.bind(this)}
                    onConfirm={this.state.modalConfirm}
                    texts={this.state.modalTexts}
                    header={this.state.modalHeader}/>

            </ContentWrapper>
        );
    }
}
