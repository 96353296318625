import React from 'react';
import {format} from "../../utils";

const ValidToInfo = (props) => {
    const getDiff = ()=>{
        return format.diffInDaysFromNow(props.order.valid_to) * -1; 
    }
    const getDaysText = ()=>{
        return `${getDiff()} ${getDiff()===1 ? "dag" : "dagar"}`;
    }
    const getAgreementReferenceNumber = ()=>{
        return props.order.type==="invoice" ? "köpnota löpnummer:%20" + props.order.contract_number : "avtalsnummer:%20" + props.order.agreement_external_reference;  
    }
    return(
            <React.Fragment>
        {
            (["shipped","canceled"].includes(props.order.status) === false)  &&  
            (getDiff() <= 30) 
            ?
            <div>
                <strong>
                Order avslutas om {getDaysText()}<br />
                </strong>
                Kontakta&#160; 
                <a href={"mailto:ehandel@wasakredit.se?subject=Förläng%20giltighetstid&body=Hej,%20jag%20vill%20förlänga%20giltighetstiden%20på%20min%20order%20med%20id:%20"+props.order.id+" och " + getAgreementReferenceNumber()}>
                    Wasa Kredit</a> för att förlänga giltighetstiden 
            </div>
        :null

        }
</React.Fragment>
    )
    
   
}
export default ValidToInfo;




