import React from 'react';
import t from "../../translation";
import {Summary} from "../OrderDetails/OrderTop";
import {
    Table,
    TBody,
    Td,
    TFoot,
    Th,
    Thead,
    Tr,
    TrTotalCost,
    TrTotalCostIncVat
} from "../OrderDetails/OrderTable";
import ItemStatus from "../styled/ItemStatus";
import orderStatus from "../../enums/orderStatus";
import { newAndShinyCurrencyFormat } from '../../utils/format';

const OrderSummary = (props) => {
    const renderCostColumns = (title, costId, cost) => {
        return <>
            <Td>{title}</Td>
            <Td/>
            <Td/>
            <Td/>
            <Td/>
            <Td id={costId} rightAlign noWrap>
                {cost}
            </Td>
        </>;
    };

    const order = props.order;


    const zeroIfCancelled = (value) =>{
        return order.status !== orderStatus.canceled ? parseFloat(value) : 0;
    }

    const totalCostExVat =() =>{
        return newAndShinyCurrencyFormat(zeroIfCancelled(order.total_price_ex_vat.amount), order.total_price_ex_vat.currency);

    } 
    const totalVat = ()=>{
        return newAndShinyCurrencyFormat(zeroIfCancelled(order.total_vat), order.total_price_ex_vat.currency);
    } 
    const totalCost = ()=>{
        return newAndShinyCurrencyFormat(zeroIfCancelled(order.total_price_incl_vat.amount) , order.total_price_incl_vat.currency);

    } 
    return (
        <React.Fragment>
            <Summary>{t(`ORDER_DETAILS_ORDER_SUMMARY_HEADER`)}</Summary>
            <Table id={"order-details-summary-table"}>
                <Thead>
                    <tr>
                        <Th>{t(`ORDER_DETAILS_PRODUCT_NAME`)}</Th>
                        <Th compress>{t(`ORDER_DETAILS_PRODUCT_ID`)}</Th>
                        <Th compress >{t(`ORDER_DETAILS_QUANTITIY`)}</Th>
                        <Th compress noWrap rightAlign>{t(`ORDER_DETAILS_QUANTITY_SHIPPED`)}</Th>
                        <Th compress noWrap rightAlign>{t(`ORDER_DETAILS_PRICE_PER_ITEM`)}</Th>
                        <Th compress noWrap rightAlign>{t(`ORDER_DETAILS_PRICE`)}</Th>
                    </tr>
                </Thead>
                <TBody>
                    {order.non_cancelled_cart_items
                        .map(
                            (item, index) =>
                                (<Tr key={item.id + "_" + index}>
                                    <Td>
                                        {item.product_name}
                                    </Td>
                                    <Td compress noWrap className='artnr-value' >
                                        {item.product_id}
                                    </Td>
                                    <Td compress noWrap centerAlign>
                                        {item.quantity}
                                    </Td>
                                    <Td compress noWrap centerAlign>
                                        <ItemStatus item={item}/>
                                    </Td>
                                    <Td compress noWrap rightAlign>
                                        {newAndShinyCurrencyFormat(item.price_ex_vat.amount, item.price_ex_vat.currency)}
                                    </Td>
                                    <Td compress noWrap rightAlign>
                                        {newAndShinyCurrencyFormat(item.total_price_ex_vat.amount, item.total_price_ex_vat.currency)}
                                    </Td>
                                </Tr>),
                        )}
                </TBody>
                <TFoot>
                    <TrTotalCost>
                        {renderCostColumns(t(`ORDER_DETAILS_PRICE_TOTAL`), "order-details-total-cost-ex-vat", totalCostExVat())}
                    </TrTotalCost>
                    <Tr>
                        {renderCostColumns(t(`ORDER_DETAILS_VAT`), "order-details-total-vat", totalVat())}
                    </Tr>
                    <TrTotalCostIncVat>
                        {renderCostColumns(t(`ORDER_DETAILS_PRICE_TOTAL_INC_VAT`), "order-details-total-cost-inc-vat", totalCost())}
                    </TrTotalCostIncVat>
                </TFoot>
            </Table>
        </React.Fragment>
    )
};

export default OrderSummary;




